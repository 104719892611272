
export default {
	name: "dailyProgress",
	data() {
		return {
			token: "",
			todayDate: "",
			todayDateArr: [], //当天时间的数组
			calendar: [],
			isSelect: "", //选中样式
			isDefault: "", //默认样式
			showDate: "", //暂时的日期
			rankingList: [], //排名的数据
			visitingManageList: [], //访视管理数据
			bigImgUrl: '',//储存小图url
			bigImg: false,//控制大图显示
			activeNames: [0],
			color(value) {
				if (value < 20) {
					return "#F73B36";
				} else if (value >= 20 && value < 80) {
					return "#FFAE00";
				} else {
					return "#36B822";
				}
			},
			partAlert: false, //备注弹框
			remarksArr: [] //备注数据
		};
	},

	created() {
		this.token = this.getCookie("token");
		var time = this.getTodayTime();
		time = this.getNowFormatDate(time);
		this.isSelect = time;
		this.todayDate = time;
		this.showDate = time;
		this.todayDateArr = this.getTodayTime();
		this.getDateData(this.todayDate);
		this.getVisitingManage(this.todayDate);
		this.getRankingData(this.seRtankingDate(this.todayDate));
	},
	methods: {
		// 获取日历
		getDateData(date) {
			var _this = this;
			_this.$http
				.post(
					"/API/PLAT/selectDateListByDate",
					{
						ym: date
					},
					{
						headers: { token: _this.token }
					}
				)
				.then(function (res) {
					if (res.data.code == 200) {
						_this.calendar = res.data.data;
					}
				});
		},
		// 格式化日期
		getNowFormatDate(timeArr) {
			if (timeArr[1] >= 1 && timeArr[1] <= 9) {
				timeArr[1] = "0" + timeArr[1];
			}
			if (timeArr[2] >= 0 && timeArr[2] <= 9) {
				timeArr[2] = "0" + timeArr[2];
			}
			var currentdate = timeArr.join("-");
			return currentdate;
		},
		// 获取当天
		getTodayTime() {
			var date = new Date();
			var seperator1 = "-";
			var year = date.getFullYear();
			var month = date.getMonth() + 1;
			var strDate = date.getDate();
			var currentdate = year + seperator1 + month + seperator1 + strDate;
			currentdate = currentdate.split("-");
			return currentdate;
		},
		// 切换日历详情
		getSameDayData(date) {
			this.isSelect = date;
			this.isDefault = this.todayDate;
			this.getVisitingManage(date);
		},
		// 获取上个月日历
		getLastMonth() {
			var time = this.todayDateArr;
			if (time[1] > 1) {
				time[1]--;
			} else if (time[1] == 1) {
				time[0]--;
				time[1] = 12;
			}
			var defaultArr = [time[0], time[1], '1'];
			var month = time.slice(0, 2);
			this.showDate = time.join("-");
			time = this.getNowFormatDate(time);
			this.isDefault = this.getNowFormatDate(defaultArr)
			this.getDateData(time);
			month = month.join("-");
			this.getRankingData(month);
		},
		// 获取下个月日历
		getNextMonth() {
			var time = this.todayDateArr;
			if (time[1] < 12) {
				time[1]++;
			} else if (time[1] == 12) {
				time[0]++;
				time[1] = 1;
			}
			var defaultArr = [time[0], time[1], '1'];

			var month = time.slice(0, 2);
			this.showDate = time.join("-");
			time = this.getNowFormatDate(time);
			this.isDefault = this.getNowFormatDate(defaultArr)
			this.getDateData(time);
			month = month.join("-");
			this.getRankingData(month);
		},
		// 设置排名的参数
		seRtankingDate(date) {
			date = date.split("-");
			date.length--;
			date = date.join("-");
			return date;
		},
		// 获取排名数据
		getRankingData(date) {
			var _this = this;
			_this.$http
				.post(
					"/API/PLAT/selectCrcListByDate",
					{
						ym: date
					},
					{
						headers: { token: _this.token }
					}
				)
				.then(function (res) {
					if (res.data.code == 200) {
						_this.rankingList = res.data.data;
					}
				});
		},
		// 获取访视管理数据
		getVisitingManage(date) {
			var _this = this;
			_this.$http
				.post(
					"/API/PLAT/selectCheckListByDate",
					{
						date: date
					},
					{
						headers: { token: _this.token }
					}
				)
				.then(function (res) {
					if (res.data.code == 200) {
						_this.visitingManageList = res.data.data;
					}
				});
		},
		clickPhoto(url) {
			//点击放大图片
			this.bigImg = true;
			this.bigImgUrl = url;
		},
		closeBigImg() {
			//关闭大图片
			this.bigImg = false;
		},
		handleChange() { },
		setPartAlert(arr) {
			this.partAlert = true;
			this.remarksArr = arr;
		},
		closeAlert() {
			this.partAlert = false;
		}
	},
	filters: {
		setDateText: function (val) {
			var timeArr = val.split("-");
			timeArr.length--;
			switch (timeArr[1]) {
				case "1":
					timeArr[1] = "一" + "月";
					break;
				case "2":
					timeArr[1] = "二" + "月";
					break;
				case "3":
					timeArr[1] = "三" + "月";
					break;
				case "4":
					timeArr[1] = "四" + "月";
					break;
				case "5":
					timeArr[1] = "五" + "月";
					break;
				case "6":
					timeArr[1] = "六" + "月";
					break;
				case "7":
					timeArr[1] = "七" + "月";
					break;
				case "8":
					timeArr[1] = "八" + "月";
					break;
				case "9":
					timeArr[1] = "九" + "月";
					break;
				case "10":
					timeArr[1] = "十" + "月";
					break;
				case "11":
					timeArr[1] = "十一" + "月";
					break;
				case "12":
					timeArr[1] = "十二" + "月";
					break;
			}
			timeArr.reverse();
			timeArr = timeArr.join(" ");
			return timeArr;
		}
	}
};
